import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide_AfterProvisionalBuilderInstalled = () => {
    const seo = {
        metaDesc: 'What do I do after ProvisionalBuilder™ is installed? - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="What do I do after ProvisionalBuilder™ is installed? - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>What do I do after ProvisionalBuilder™ is installed?</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                                <img width="300" height="300"
                                    src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
                                    data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
                                    data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
                                    srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w" />
                            </figure>
                            <div class="wpb_wrapper">
                                <h2><b>What do I do after ProvisionalBuilder</b><span style={{ fontWeight: 400 }}>™</span><b> is installed?</b></h2>
                                <p><span style={{ fontWeight: 400 }}>After start-up, you will see the home page where your work is organized. You can
                                    create a new application, open a previously created application, or get answers to your questions through
                                    the “Help” button.</span></p>
                                <ul>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>New Application – Begin a new patent application
                                        by selecting the “New Application” button.&nbsp; Alternatively, you may click on “File” in the upper
                                        left and select “New” from the drop-down menu.</span></li>
                                </ul>
                                <ul>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Open – Open a patent application. Alternatively,
                                        you may click on the “File” menu to reveal the drop-down menu and select “Open” to upload the
                                        application from its saved location. A history of previously created or accessed applications is
                                        displayed below the main menu whenever you run ProvisionalBuilder™. You can click on the desired patent
                                        application to open it.</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Request Attorney – Send an e-mail requesting
                                        assistance from a patent attorney. You can select a particular application and click on the “Request
                                        Attorney” button to request help. If there is no project or a particular project is not selected, the
                                        “Request Attorney” button is disabled or grayed-out. Alternatively, you may click on the “Help” menu and
                                        select “Request Attorney Filing” from the drop-down menu.</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Help – Access the reference guide. Alternatively,
                                        you may click on the “Help” button or select “Power Patent Help” from the drop-down menu. When you click
                                        on the “Help” button, a series of topics will be visible. For inventors unfamiliar with a PPA, the
                                        “Help” button provides basic information to get you started.</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>To return to the main menu at any point in the
                                        program, select the “Home” icon in the upper left corner.</span></li>
                                </ul>
                            </div>
                            <div className='mt-5'>
                                <p>
                                    <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide_AfterProvisionalBuilderInstalled;